import React from 'react'
import Link from './Link'
import { internalLinks } from '../assets/lib/constants'

const Footer = () => {
  // '採用情報' excluded since it is shown as button in footer
  const filteredInternalLinks = internalLinks.filter((link) => link.title !== '採用情報') 

  return (
    <footer className="footer">
      <div className="section__inner section__inner--footer">
        <div className="footer__logo">
          <Link to="/"><img src="/image/common/logo_hz_white.svg" className="footer__logo-img" alt="もばらぶ" /></Link>
        </div>
        <ul className="footer__nav">
          {filteredInternalLinks.map((link) => (
            <li className="footer__nav-item" key={link.path}>
              <Link to={link.path} className="footer__nav-link">{ link.title }</Link>
            </li>
          ))}
          <li className="footer__nav-item">
            <Link href="https://blog.mobalab.net/" className="footer__nav-link">会社ブログ</Link>
          </li>
          <li className="footer__nav-item">
            <Link href="https://engineering.mobalab.net/" className="footer__nav-link">技術者ブログ</Link>
          </li>
        </ul>
        <div className="footer__cta">
          <div className="footer__recruit">
            <p className="footer__cta-txt">一緒に働いてくれるメンバーを募集中です</p>
            <Link className="btn-white" to="/recruit/">採用情報</Link>
          </div>
          <div className="footer__contact">
            <p className="footer__cta-txt">お仕事の依頼などお気軽にお問い合わせください</p>
            <Link className="btn-white" href="https://blog.mobalab.net/inquiry/">お問い合わせ</Link>
          </div>
          <div className="footer__doucument">
            <p className="footer__cta-txt">システム開発に関する最新の調査は<br />
              こちらからダウンロードいただけます</p>
            <Link className="btn-white" href="https://blog.mobalab.net/document-download-form/">資料請求</Link>
          </div>
        </div>
        <div className="footer__socmed-section">
          <p className="footer__cta-txt">Connect with Us</p>
          <Link to="https://twitter.com/mobalab" target="_blank">
            <img className="footer__twitter-logo footer__socmed-item" src="/image/common/twitter-logo.png" alt="twitter"></img>
          </Link>
        </div>
        <div className="copyright">
          <small>&copy; Mobalab, KK</small>
        </div>
      </div>
    </footer>
  )
}

export default Footer

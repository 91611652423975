import React from 'react'
import Helmet from 'react-helmet'
import '../assets/sass/style.scss'
import Footer from '../components/Footer'

const Layout = ({ children, title, description, path, pathName }) => {
  const titleToDisplay = title || '株式会社もばらぶ | Mobalab, KK'
  const descriptionToDisplay = description || '株式会社もばらぶは、千葉県茂原市にある、遠隔勤務など新しい働き方を推進しているソフトウェア開発企業です。高い技術力を元に、高品質、適正価格なシステムを短納期で納品します'
  const currentPath = `https://mobalab.net${pathName || ''}`

  return (
    <>
      <Helmet title="もばらぶエンジニアブログ – リモート開発メインのソフトウェア開発企業のエンジニアブログです">
        <html lang="ja" />
        <title>{ titleToDisplay }</title>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta charset="utf-8" />
        <meta name="description" content={ descriptionToDisplay } />
        <meta name="keywords" content="もばらぶ,Mobalab,ソフトウェア開発,リモートワーク,テレワーク,茂原市" />
        <meta name="author" content="株式会社もばらぶ" />
        <meta property="og:title" content={ titleToDisplay } />
        <meta property="og:description" content={ descriptionToDisplay } />
        <meta property="og:url" content={currentPath} />
        <meta property="og:image" content="https://mobalab.net/image/common/logo-ogp.png" />
        <meta property="og:site_name" content="株式会社もばらぶ" />
        <meta property="og:type" content="website" />

      </Helmet>
      { children }
      <Footer />
    </>
  )
}

export default Layout

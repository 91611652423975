import React, { useState } from 'react'
import Link from './Link'
import { internalLinks } from '../assets/lib/constants'

const MobileMenu = (props) => {

  const [ accordionOpen, setAccordionOpen ] = useState(false)

  const toggleBlogLinks = () => {
    setAccordionOpen(!accordionOpen)
  }

  return (
    <nav className="toggle-box">
      <ul className="toggle-box__list">
        {internalLinks.map((link) => (
          <li className="toggle-box__list-item" key={link.path}>
            <Link
              to={ link.path }
              onClick={props.onClickedInternalLink} 
              className="toggle-box__list-link"
            >
              { link.title }
            </Link>
          </li>
        ))}
        <li className="toggle-box__list-item" onClick={toggleBlogLinks}>
          <div className="toggle-box__list-link">ブログ</div>
        </li>
        <div className={`toggle-box__blog-links-accordion ${accordionOpen && 'toggle-box__blog-links-accordion--open'}`} >
          <Link className="toggle-box__blog-link" href="https://blog.mobalab.net/">会社ブログ</Link>
          <Link className="toggle-box__blog-link" href="https://engineering.mobalab.net/">技術者ブログ</Link>
        </div>
        <li className="toggle-box__list-item">
          <Link href="https://blog.mobalab.net/inquiry/" className="toggle-box__list-link">お問い合わせ</Link>
        </li>
        <li className="toggle-box__list-item">
          <Link href="https://twitter.com/mobalab/" className="toggle-box__list-link" target="_blank"><img className="header__twitter-logo" src="/image/common/twitter-logo.png" alt="twitter"></img></Link>
        </li>
      </ul>
    </nav>
  )
}

export default MobileMenu

import React from 'react'
import { Link as GatsbyLinkComponent } from 'gatsby'

// if has 'href' prop, treat as external link
const Link = (props) => {
  if (!props.href) {
    return <GatsbyLinkComponent {...props}>{ props.children }</GatsbyLinkComponent>
  } else {
    return <a {...props} target="_blank" rel="noopener noreferrer">{ props.children }</a>
  }
}

export default Link

import React, { useState } from 'react'
import Navigation from './Navigation'
import MobileMenu from './MobileMenu'
import Particles from 'react-tsparticles'
import config from '../assets/lib/particles.json'

const TopPageDescription = () => (
  <div className="mainvis">
    <Particles className="mainvis__background" params={config}/>
    <div className="mainvis__title">
      <h1 className="mainvis__logo">
        <img src="image/top/logo_2line.svg" className="mainvis__logo-img" alt="もばらぶ" />
      </h1>
      <p className="mainvis__read">働く場所・時間帯を、働く人が自由に選べるような社会にする</p>
    </div>
  </div>
)

const Header = ({ isTopPage }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    if (!mobileMenuOpen) {
      document.body.classList.add('open')
    } else {
      document.body.classList.remove('open')
    }
    setMobileMenuOpen(!mobileMenuOpen)
  }

  if (isTopPage) {
    return (
      <>
        <header className="header header--top">
          <TopPageDescription />
        </header>
        <Navigation onClickedMenuButton={toggleMobileMenu} />
        <MobileMenu onClickedInternalLink={toggleMobileMenu} />
      </>
    )
  } else {
    return (
      <>
        <header className="header">
          <Navigation onClickedMenuButton={toggleMobileMenu} />
        </header>
        <MobileMenu onClickedInternalLink={toggleMobileMenu} />
      </>
    )
  }
}

export default Header
